<template>
  <div>
    <Loading v-show="loading"></Loading>
    <v-form
      ref="form"
      class="multi-col-validation"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <label>Type 1</label>
          <div class="demo-space-x d-flex flex-wrap">
            <v-radio-group
              v-model="rowRadioType1"
              row
              hide-details
            >
              <div
                v-for="type in types1"
                :key="type.id"
              >
                <v-radio
                  :value="type.id"
                  :label="type.name"
                  @click="clickType1"
                />
              </div>
            </v-radio-group>
          </div>
        </v-col>
        <v-col
          cols="12"
        >
          <label>Type 2</label>
          <div class="demo-space-x d-flex flex-wrap">
            <v-radio-group
              v-model="rowRadioType2"
              row
              hide-details
            >
              <div
                v-for="type in types2"
                :key="type.id"
              >
                <v-radio
                  :value="type.id"
                  :label="type.name"
                />
              </div>
            </v-radio-group>
          </div>
        </v-col>
        <v-col
          cols="12"
        >
          <label>Type 3</label>
          <div class="demo-space-x d-flex flex-wrap">
            <div
              v-for="type in types3"
              :key="type.id"
            >
              <v-checkbox
                v-model="type3SelectedValues"
                :value="type.id"
                :label="type.name"
                hide-details
              />
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
        >
          <label>Duration</label>
          <div>
            <date-range-picker
              ref="datePicker"
              :search-btn-show="false"
              :shortcuts-show="false"
            ></date-range-picker>
          </div>
        </v-col>

        <v-col cols="12">
          <v-btn
            style="text-transform: none"
            color="primary"
            @click="search"
          >
            Search
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mdiDownloadOutline } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import DateRangePicker from '@/views/forms/form-elements/datepicker/DateRangePicker.vue'
import store from '@/store'
import { isEmpty, getSessionData, date2yyyymmddhhmmss, dateFormattedddmmmyyydd, date2String } from '@core/utils'
import csvDownloadStoreModule from './csvDownloadStoreModule'
import Loading from '@/views/components/load/Loading.vue'
import { getCsvData } from './csvConfig'

const USER_APP_STORE_MODULE_NAME = 'app-csvdownload'
const currentDate = new Date()
const type3SelectedValues = ref([1, 2, 3])

export default {
  components: {
    DateRangePicker,
    Loading,
  },
  mounted() {
    // 日付選択コンポーネントを非表示させる
    this.$dateRangePickerDisplayFlag = false
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false
    this.$datePickerDisplayFlag = false
  },
  methods: {
    clickType1() {
      console.log(this.rowRadioType1)
      if (this.rowRadioType1 === 1) {
        this.types2 = [
          { id: 1, name: 'Hourly' },
          { id: 3, name: '30 Mins' },
          { id: 2, name: 'Daily' },
        ]
      } else {
        this.types2 = [
          { id: 1, name: 'Hourly' },
          { id: 2, name: 'Daily' },
        ]
      }
    },
    csvData2Str(csvData, tempData) {
      let str
      if (!isEmpty(csvData.sc)) {
        if (!isEmpty(csvData.type)) {
          str = `${csvData.sc}\n${csvData.type}\n${csvData.title}\n`
        } else {
          str = `${csvData.sc}\n${csvData.title}\n`
        }
      } else {
        str = `\n${csvData.title}\n`
      }

      const lenElement = Object.keys(tempData).length
      for (let i = 0; i < lenElement; i += 1) {
        str = `${str}${tempData[i][0]},`
        const lenTempData = Object.keys(tempData[i][1]).length
        for (let j = 0; j < lenTempData; j += 1) {
          if (j === lenTempData - 1) {
            str = `${str}${tempData[i][1][j]}\n`
          } else {
            str = `${str}${tempData[i][1][j]},`
          }
        }
      }

      return str
    },
    search() {
      const isFormValid = this.$refs.form.validate()

      if (!isFormValid) return
      const searchStartDate = date2String(this.$refs.datePicker.dateRange[0])
      const searchEndDate = date2String(this.$refs.datePicker.dateRange[1])

      let downloadType = ''

      const siteId = String(getSessionData('data').siteId)
      const startDateString = dateFormattedddmmmyyydd(searchStartDate)
      const endDateString = dateFormattedddmmmyyydd(searchEndDate)
      if (this.rowRadioType1 === 1 && this.rowRadioType2 === 1) {
        downloadType = '1'
      } else if (this.rowRadioType1 === 1 && this.rowRadioType2 === 3) {
        // 30mins
        downloadType = '7'
      } else if (this.rowRadioType1 === 1 && this.rowRadioType2 === 2) {
        downloadType = '2'

        // Corridor Hour
      } else if (this.rowRadioType1 === 2 && this.rowRadioType2 === 1) {
        downloadType = '3'
      } else if (this.rowRadioType1 === 2 && this.rowRadioType2 === 2) {
        downloadType = '4'
      } else if (this.rowRadioType1 === 3 && this.rowRadioType2 === 1) {
        downloadType = '5'
      } else if (this.rowRadioType1 === 3 && this.rowRadioType2 === 2) {
        downloadType = '6'
      }

      const csvData = getCsvData(siteId, downloadType, startDateString, endDateString)

      const tempData = []
      if (this.rowRadioType1 === 1) {
        let header1Temp = []
        let header2Temp = []
        header1Temp = ['', csvData.header1]
        if (this.rowRadioType2 === 1) {
          header2Temp = ['', csvData.header2]
        } else {
          header2Temp = ['Date', csvData.header2]
        }

        tempData.push(header1Temp)
        tempData.push(header2Temp)
      } else if (this.rowRadioType1 === 2) {
        let header1Temp = []
        let header2Temp = []
        let header3Temp = []
        let header4Temp = []
        header1Temp = ['', csvData.header1]
        header2Temp = ['', csvData.header2]
        header3Temp = ['', csvData.header3]
        if (this.rowRadioType2 === 1) {
          header4Temp = ['Date Time', csvData.header4]
        } else {
          header4Temp = ['Date', csvData.header4]
        }

        tempData.push(header1Temp)
        tempData.push(header2Temp)
        tempData.push(header3Temp)
        tempData.push(header4Temp)
      } else if (this.rowRadioType1 === 3) {
        let header1Temp = []
        let header2Temp = []
        let header3Temp = []
        header1Temp = ['', csvData.header1]
        header2Temp = ['', csvData.header2]
        if (this.rowRadioType2 === 1) {
          header3Temp = ['DateTime', csvData.header3]
        } else {
          header3Temp = ['Date', csvData.header3]
        }

        tempData.push(header1Temp)
        tempData.push(header2Temp)
        tempData.push(header3Temp)
      }

      let weekDayFlag = 0
      let saturDayFlag = 0
      let sunDayFlag = 0
      if (this.type3SelectedValues.indexOf(1) !== -1) {
        weekDayFlag = 1
      }
      if (this.type3SelectedValues.indexOf(2) !== -1) {
        saturDayFlag = 1
      }
      if (this.type3SelectedValues.indexOf(3) !== -1) {
        sunDayFlag = 1
      }
      this.loading = true
      store
        .dispatch('app-csvdownload/fetchData', {
          params: {
            siteId,
            searchStartDate,
            searchEndDate,
            downloadType,
            weekDayFlag,
            saturDayFlag,
            sunDayFlag,
          },
        })
        .then(response => {
          this.loading = false
          csvData.data = response.data.result.data.data
          if (downloadType === '1' || downloadType === '7') {
            csvData.data.forEach(item => {
              const counts = []
              counts.push(item.counts)

              let totalCount = 0
              for (let i = 0; i < item.counts.length; i += 1) {
                totalCount += Number(item.counts[i])
              }

              counts.push(totalCount)

              const temp = [item.time, counts]
              tempData.push(temp)
            })
          } else if (downloadType === '2') {
            csvData.data.forEach(item => {
              const counts = []
              counts.push(item.counts)

              let totalCount = 0
              for (let i = 0; i < item.counts.length; i += 1) {
                let j

                // PP メモ機能がないため、
                if (siteId === '2') {
                  j = 4
                } else {
                  j = 6
                }
                if (i > j) {
                  totalCount += Number(item.counts[i])
                }
              }

              counts.push(totalCount)

              const temp = [item.time, counts]
              tempData.push(temp)
            })
          } else if (downloadType === '3' || downloadType === '4' || downloadType === '5' || downloadType === '6') {
            csvData.data.forEach(item => {
              const counts = []
              counts.push(item.counts)
              const temp = [item.time, counts]
              tempData.push(temp)
            })
          }

          const fileName = date2yyyymmddhhmmss(new Date())
          const context = this.csvData2Str(csvData, tempData)

          const bom = new Uint8Array([0xef, 0xbb, 0xbf])
          const blob = new Blob([bom, context], { type: 'text/csv' }) // 配列に上記の文字列(str)を設定
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileName
          link.click()
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.$router.push({ name: 'error-login' })
        })
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, csvDownloadStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    let startDateString = sessionStorage.getItem('startDate')
    let endDateString = sessionStorage.getItem('endDate')

    if (startDateString === '') {
      startDateString = currentDate
      endDateString = currentDate
    }

    const initDateRange = {
      startDate: startDateString,
      endDate: endDateString,
    }

    const siteId = String(getSessionData('data').siteId)

    const types1 = ref([])
    if (siteId === '16') {
      types1.value = [
        { id: 1, name: 'Entrance' },
        { id: 2, name: 'Corridor' },
        { id: 3, name: 'Toilet' },
      ]
    } else if (siteId === '11' || siteId === '2' || siteId === '14') {
      types1.value = [{ id: 1, name: 'Entrance' }]
    } else {
      types1.value = [
        { id: 1, name: 'Entrance' },
        { id: 2, name: 'Corridor' },
      ]
    }

    const types2 = [
      { id: 1, name: 'Hourly' },
      { id: 3, name: '30 Mins' },
      { id: 2, name: 'Daily' },
    ]

    const types3 = [
      { id: 1, name: 'Weekday' },
      { id: 2, name: 'Saturday' },
      { id: 3, name: 'Sunday' },
    ]

    return {
      initDateRange,
      types1,
      types2,
      types3,
      type3SelectedValues,
      rowRadioType1: 1,
      rowRadioType2: 1,
      isEmpty,
      icons: {
        mdiDownloadOutline,
      },
      loading: false,
      getCsvData,
    }
  },
}
</script>
